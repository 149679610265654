require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

require("trix")
require("@rails/actiontext")

window.jQuery = $
window.$ = $

import "lodash";

import axios from 'axios';
import 'jquery';
import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/dist/css/bootstrap'
import "jquery-ui";

require("local-time").start()
// import 'data-confirm-modal'

import '../javascript/noty';
import '../javascript/select2';
import '../javascript/flatpickr'
import '../javascript/fancybox';
import '../javascript/fade-in-scroll-load'

import "../stylesheets/home";
import "@fortawesome/fontawesome-free/css/all"

document.addEventListener("DOMContentLoaded", function() {
  document.querySelectorAll("action-text-attachment").forEach(function(i){
    let src = i.getAttribute("url");
    if(src.indexOf('img.youtube.com/vi/') >= 0){
      let youtube_id = src.split("img.youtube.com/vi/").pop().split("/0.jpg")[0];
      let embed = new DOMParser().parseFromString(`<iframe width="100%" height="315" src="https://www.youtube.com/embed/${youtube_id}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`, 'text/html').body.firstChild;
      i.replaceWith(embed);
    };
  })
});


document.addEventListener("DOMContentLoaded", function(){
// make it as accordion for smaller screens
if (window.innerWidth > 992) {

	document.querySelectorAll('.navbar .nav-item').forEach(function(everyitem){

		everyitem.addEventListener('mouseover', function(e){

			let el_link = this.querySelector('a[data-toggle]');

			if(el_link != null){
				let nextEl = el_link.nextElementSibling;
				el_link.classList.add('show');
				nextEl.classList.add('show');
			}

		});
		everyitem.addEventListener('mouseleave', function(e){
			let el_link = this.querySelector('a[data-toggle]');

			if(el_link != null){
				let nextEl = el_link.nextElementSibling;
				el_link.classList.remove('show');
				nextEl.classList.remove('show');
			}


		})
	});

}
// end if innerWidth
}); 
// DOMContentLoaded  end


$(function () {
  $(document).scroll(function () {
    var $nav = $(".navbar-fixed-top");
    $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
  });
});